import React from 'react'

export default function WorkPremiseLink({children, link, hasImage}){
    return (
      <a
        href={link}
        className="mb-6 w-full rounded overflow-hidden shadow-md transition-all duration-300 hover:shadow-xl border border-gray hover:border-opacity-100 hover:border-pink"
        target="_blank"
        rel="noreferrer"
      >
        {children}
      </a>
    )
}