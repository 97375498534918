import React from "react"
import Image from "../components/image"

export default function WorkContent(props) {
  return (
    <div>
      {props.work.image && (
        <div>
          <Image imgName={props.work.image} />
        </div>
      )}

      <div className="p-6">
        <h2
          className="font-semibold text-2xl leading-tight tracking-tight mb-2"
        >
          {props.work.name}
        </h2>
        {props.work.description && (
          <p className="text-gray">{props.work.description}</p>
        )}

        <div className="mt-3 uppercase text-pink text-sm">
          {props.work.tags.map((tag, i) => {
            return (
              <span key={i} className="inline-block border-pink border px-2 mr-2 mb-2">
                {tag}
              </span>
            )
          })}
        </div>
      </div>
    </div>
  )
}
