import React from "react"
import Navbar from "../components/navbar"
import Work from "../components/work"
import SEO from "../components/SEO"
import { graphql } from "gatsby"

const Showcase = (data) => {
  const edges = data.data.allShowcaseJson.edges
  const works = edges.map(edge => <Work key={edge.node.id} work={edge.node} />)

  return (
    <main>
      <SEO title="Showcase" pathname="/showcase" />

      <Navbar></Navbar>

      <div className="lg:max-w-screen-md mx-auto p-6 lg:pt-32 pt-24">
        <h1 className="lg:text-center tracking-tighter">
          <span className="text-pink">/</span>showcase
        </h1>
        <p className="text-xl">
          Bits of my work from a multitude of fun projects, personal
          commissions, or broken pencils. When I am not binge watching Netflix,
          I love to run different experiments with my
          code/illustrator/figma/notebook from time to time. <i>Sometimes</i>,
          they end up here.
        </p>
      </div>

      <div
        className="p-6 masonry mb-10"
        id="showcase"
      >
        {works}
      </div>
    </main>
  )
}

export default Showcase

export const pageQuery = graphql`
  {
    allShowcaseJson {
      edges {
        node {
          name
          id
          image
          link
          tags
          description
        }
      }
    }
  }
`
