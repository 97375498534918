import React from "react"
import WorkPremiseLink from "./workWithLink"
import WorkPremise from "./workWithoutLink"
import WorkContent from "./workContent"

export default function Work(props) {
  return (
    <>
      {props.work.link ? (
        <WorkPremiseLink link={props.work.link} hasImage={props.work.image}>
          <WorkContent work={props.work}></WorkContent>
        </WorkPremiseLink>
      ) : (
        <WorkPremise hasImage={props.work.image}>
          <WorkContent work={props.work}></WorkContent>
        </WorkPremise>
      )}
    </>
  )
}
