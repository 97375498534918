import React from 'react'

export default function WorkPremise({children, hasImage}){
    return (
      <div
        className="mb-6 w-full relative inline-block rounded overflow-hidden shadow-md transition-all duration-300 transform hover:shadow-xl border border-gray"
      > 
        {children}
      </div>
    )
}